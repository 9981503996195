import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicies',
  templateUrl: './servicies.component.html',
  styleUrls: ['./servicies.component.scss']
})
export class ServiciesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
