<div class="cms-product">
  <app-page-header [dataHeader]="data"></app-page-header>
  <app-error></app-error>
  <!---<div class="link">
    <a class="button-effect outline-effect" href="https://admin.twentio.cz" target="_blank">
      Zobrazit web
    </a>
  </div>--->
</div>
<app-footer></app-footer>
