import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

export interface Data {
  title: string;
  image: string;
}

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent implements OnInit {

  data: Data = { title: 'Reference', image: 'url(../../../assets/images/testimonials/testimonials.jpg)' }


  public constructor(private titleService: Title) { }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  ngOnInit(): void {
    this.titleService.setTitle('Reference | Twentio');
  }

}
