<app-page-header [dataHeader]="data"></app-page-header>
<div class="contacts">
  <div class="container">
    <ul>
      <li *ngFor="let contact of dataContacts">
        <div class="image" [ngStyle]="{background: 'url(' + contact.photo + ')'}" [title]="contact.description"></div>
        <div class="content">
          <ul class="informations">
            <li [title]="contact.description">
              <div class="flex">
                <h3>
                  {{contact.name}}
                </h3>
                <p>
                  {{contact.type}}
                </p>
              </div>
            </li>
            <li>
              <span class="material-icons">
                mail
              </span>
              <a [href]="'mailto:'+contact.email">
                {{contact.email}}
              </a>
            </li>
            <li *ngIf="contact.phoneNumber !== ''">
              <span class="material-icons">
                local_phone
              </span>
              <a [href]=" 'tel:'+ removeWhiteSpace(contact.phoneNumber)">
                {{contact.phoneNumber}}
              </a>
            </li>
            <li *ngIf="contact.externalWebsite !== ''">
              <span class="material-icons">
                language
              </span>
              <a [href]="contact.externalWebsite" *ngIf="26 > contact.externalWebsite.length" target="_blank">
                {{contact.externalWebsite}}
              </a>
              <a [href]="contact.externalWebsite" target="_blank" *ngIf="contact.externalWebsite.length > 26">
                {{contact.externalWebsite.substr(0,25) + '...'}}
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li *ngIf="easterEgg" class="ema">
        <div class="image" title="MOST DOPE PERSON"
          style="background: url(https://scontent-prg1-1.xx.fbcdn.net/v/t1.0-9/75380366_2474128269303685_5693958356146847744_n.jpg?_nc_cat=101&ccb=2&_nc_sid=174925&_nc_ohc=7hU98KhOIwoAX9h8ctM&_nc_ht=scontent-prg1-1.xx&oh=40fa3af3f2c99a8a5d8e0292571b2034&oe=5FEE5177);">
        </div>
        <div class="content">
          <ul class="informations">
            <li title="MOST DOPE PERSON">
              <div class="flex">
                <h3 style="font-family: 'Comic Sans MS';color: #ec407a;">
                  Ema Urbánková
                </h3>
                <p style="font-family: 'Comic Sans MS';color: #ec407a;">
                  Comic Sans O.G.
                </p>
              </div>
            </li>
            <li>
              <span class="material-icons" style="
                 /* font-family: 'Comic Sans MS'; */
                 color: #ec407a;
                 "> local_phone </span><a href="https://www.disney.cz/" style="
                 font-family: 'Comic Sans MS';
                 color: #ec407a;
                 " target="_blank"> Telefon ti tak nedá </a></li>
            <li><span class="material-icons" style="
                 color: #ec407a;
                 "> language </span><a target="_blank" href="https://instagram.com/abcema/" style="
                 font-family: 'Comic Sans MS';
                 color: #ec407a;
                 ">https://instagram.com/abcema/</a></li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</div>
<app-testimonials></app-testimonials>
<app-footer></app-footer>
