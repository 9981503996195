import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './views/homepage/homepage.component';
import { ContactComponent } from './views/contact/contact.component';
import { ReferenceComponent } from './views/reference/reference.component';
import { DemandComponent } from './views/demand/demand.component';
import { OurServiciesComponent } from './views/our-servicies/our-servicies.component';
import { ErrorComponent } from './components/error/error.component';
import { CmsComponent } from './views/cms/cms.component';
import { ProjectComponent } from './views/project/project.component';


/* POUZIT V PRIPADE VYVOJI PAGE, NEBO POUZIT ERROR COMPONENT */
import { CustomComponent } from './views/custom/custom.component';


const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'dev', component: HomepageComponent },
  { path: 'kontakt', component: ContactComponent },
  { path: 'kontakty', component: ContactComponent },
  { path: 'poptavka', component: DemandComponent },
  { path: 'nezavazna-poptavka', component: DemandComponent },
  { path: 'reference', component: ReferenceComponent },
  { path: 'projekt/:slug', component: ProjectComponent },
  { path: 'sluzby', component: OurServiciesComponent },
  { path: 'cms', component: CmsComponent },
  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
