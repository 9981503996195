<div class="project">
  <div class="container">
    <div class="heading">
      <div class="logo">
        <img [src]="project.logo">
      </div>
      <div class="types">
        <ul>
          <li *ngFor="let type of project.type">
            {{type.category}}
          </li>
        </ul>
      </div>
      <p>
        {{project.text}}
      </p>
    </div>
    <p *ngIf="project.beforeImage != ''"><b>Původní web:</b></p>
    <img *ngIf="project.beforeImage != ''" class="detail" [src]="project.beforeImage">
    <p *ngIf="project.beforeImage != ''"><b>Nový web:</b></p>
    <img class="detail" [src]="project.imageDetail">
    <ul>
      <li>
        <div class="head">
          <h1>Použité</h1>
          <h1 class="colored">technologie</h1>
        </div>
        <p>
          {{project.technology}}
        </p>
      </li>
    </ul>
    <div class="link">
      <a class="button-effect outline-effect" [href]="project.url" target="_blank">
        {{project.url}}
      </a>
    </div>
  </div>
</div>
