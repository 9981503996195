<app-page-header [dataHeader]="data"></app-page-header>


<app-projects-list></app-projects-list>

<!---

<app-error></app-error>
-->

<app-footer></app-footer>