import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';

export interface Links {
  title: string;
  route: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  scrolled: boolean = false;
  menu: boolean = false;
  linksData: Links[] = [
    { title: 'Domů', route: '/' },
    { title: 'Služby', route: '/sluzby' },
    { title: 'Reference', route: '/reference' },
    { title: 'Nezávazná poptávka', route: '/poptavka' },
    { title: 'Kontakt', route: '/kontakt' },
  ]

  toggle() {
    this.menu = !this.menu;
  }

  @ViewChild('servicies') scrollOne: ElementRef;
  @ViewChild('home') scrollTwo: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }
  updateScroll() {
    const scrollOne = this.scrollOne.nativeElement as HTMLElement;
    const scrollTwo = this.scrollTwo.nativeElement as HTMLElement;

    // do logic and set
    scrollTwo.scrollLeft = scrollOne.scrollLeft;
  }

  @HostListener('window:scroll')
  onScroll(): void {

    // kontrolovani kdy je scroll
    var currentScroll = document.documentElement.scrollTop;
    var h = window.innerHeight;
    var hash = window.location.pathname;

    if (hash == "/" || hash == "/dev" || hash == "/nezavazna-poptavka" || hash == "/poptavka") {
      if (currentScroll > h - 175) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }

    }
    else if (hash == "/kontakt" || hash == "/sluzby" || hash == "/reference" || hash == "/cms"|| hash.includes("projekt")) {
      if (currentScroll > 200) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    }


    //zmenit bg pri scrollu smooth na pozici kde je urcita class
    if (hash == "/" || hash == "/dev") {
      var productElement = document.querySelector(".products");
      var productCoords = productElement.getBoundingClientRect();
      if (currentScroll >= productCoords.bottom * 1.5 && currentScroll <= productCoords.bottom * 3) {
        document.querySelector('body').classList.add('product');
      }
      else {
        document.querySelector('body').classList.remove('product');
      }

      var serviciesElement = document.querySelector(".servicies");
      var serviciesCoords = serviciesElement.getBoundingClientRect();
      if (currentScroll >= serviciesCoords.top * 0.5) {
        document.querySelector('.our-servicies-list').classList.add('transform');
      }
      else {
        document.querySelector('.our-servicies-list').classList.remove('transform');
      }
    }
  }
}
