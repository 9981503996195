import { Component, OnInit } from '@angular/core';

interface Products{
  title:string;
  image:string;
}

@Component({
  selector: 'app-demand-design',
  templateUrl: './demand-design.component.html',
  styleUrls: ['./demand-design.component.scss']
})
export class DemandDesignComponent implements OnInit {

  filteredItems:Products[];
  products:Products[]=[
    {title:'Volný pád',image:'../../../assets/images/gifs/demand/falling.gif'},
    {title:'Brýle',image:'../../../assets/images/gifs/demand/glasses.gif'},
    {title:'Plastový bazén',image:'../../../assets/images/gifs/demand/pool.gif'},
    {title:'Zájezd k moři',image:'../../../assets/images/gifs/demand/sea.gif'},
    {title:'Natahovací jazyk',image:'../../../assets/images/gifs/demand/tongue.gif'},
    {title:'Jídlo',image:'../../../assets/images/gifs/demand/food.gif'},
    {title:'Basketbalový koš',image:'../../../assets/images/gifs/demand/basket.gif'},
  ]
  constructor() { }

  ngOnInit(): void {
    this.filteredItems = this.products;
  }

  filterByName(inputValue): Products[] {

    let inputNormalized = inputValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    /* 
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()    
    zajistuje, aby byl filtrovaci text bez interpunkci a prevede se do standardizovaneho modelu 
    */

    return this.filteredItems = !this.products
      ? []
      : inputNormalized.length > 0
        ? this.products.filter(d => d.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(inputNormalized))
        : this.products;
  }

}
