<div class="bad-design">
  <div class="container">
    <div class="head">
      <h1>Toto je již</h1>
      <div class="flex">
        <h1>Minu</h1>
        <h1 class="colored">LOST</h1>
      </div>
    </div>
  </div>
  <div class="design">
    <div class="container">
      <div class="pseudo-nav">
        <div class="logo">
          <a routerLink="/">
            <svg width="93" height="37" viewBox="0 0 93 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M26.7519 36.3943V28.2455H14.1824V28.1354L17.7419 25.3824C22.0244 21.9137 26.1401 17.2887 26.1401 11.5625C26.1401 4.51488 21.1345 0 12.7363 0C7.61955 0 3.05895 1.70684 0.278088 3.74405L3.00333 10.8467C5.00555 9.41518 7.73079 7.92857 10.7341 7.92857C14.4048 7.92857 16.129 9.80059 16.129 12.3884C16.0734 16.0223 12.347 19.7664 5.00555 26.0432L0 30.3929V36.3943H26.7519Z"
                fill="white" />
              <path
                d="M45.5639 0C35.386 0 31.3259 8.86458 31.3259 18.5551C31.3259 27.9702 35.1635 37 45.5083 37C55.2413 37 59.7463 29.0164 59.7463 18.4449C59.7463 9.30506 56.1312 0 45.5639 0ZM45.5639 7.48809C48.2336 7.48809 49.7352 10.9568 49.7352 18.5C49.7352 26.2083 48.1779 29.567 45.6195 29.567C42.9499 29.567 41.2814 26.2634 41.337 18.5C41.337 10.7917 43.0055 7.48809 45.5639 7.48809Z"
                fill="white" />
              <path
                d="M70.2343 36.3943V18.3348H63.5232V36.3943H70.2343ZM66.8973 10.2227C64.8209 10.2227 63.4861 11.5441 63.4861 13.3795C63.4861 15.1047 64.7839 16.4995 66.8602 16.4995C68.9737 16.4995 70.3085 15.1047 70.3085 13.3795C70.2714 11.5441 68.9737 10.2227 66.8973 10.2227Z"
                fill="white" />
              <path
                d="M83.508 17.9311C77.2789 17.9311 73.4598 21.7485 73.4598 27.5114C73.4598 33.3844 77.6867 36.7981 83.1372 36.7981C88.254 36.7981 93 33.7515 93 27.181C93 21.7485 89.218 17.9311 83.508 17.9311ZM83.3226 22.5928C85.2877 22.5928 86.1035 24.8686 86.1035 27.3646C86.1035 30.2644 85.1394 32.1364 83.3226 32.1364C81.2462 32.1364 80.3564 30.0809 80.3564 27.3646C80.3564 24.9787 81.0979 22.5928 83.3226 22.5928Z"
                fill="white" />
            </svg>
          </a>
        </div>
        <div class="semi-nav">
          <div class="content">
            <h1> NECHTE SI U NÁS UDĚLAT WEB A BUĎTE ONLINE PO CELÝ ROK!!!!!!!</h1>
          </div>
        </div>
        <ul>
          <li>
            <a routerLink="/">
              Domů
            </a>
          </li>
          <li>
            <a routerLink="/sluzby">
              Služby
            </a>
          </li>
          <li>
            <a routerLink="/reference">
              Reference
            </a>
          </li>
          <li>
            <a routerLink="/kontakt">
              Kontakt
            </a>
          </li>
        </ul>
      </div>
      <div class="working">
        <i>PRACUJEME S NEJMODERNĚJŠÍMI TECHNOLOGIEMI</i>
      </div>
      <div class="search">
        <input placeholder="Hledaný výraz" #input (input)="filterByName(input.value)">
      </div>
      <div class="content-page">
        <div class="text">
          <h1>O nás</h1>
          <p>Jsme parta mladých progresivních lidí, kteří vytváří individuální řešení</p>
        </div>
        <div class="products">
          <h1>Produkty</h1>
          <ul>
            <li *ngFor="let product of filteredItems">
              <img [src]="product.image">
              <p>{{product.title}}</p>
            </li>
            <ng-container *ngIf="filteredItems.length == 0">
                Promiň ale tento produkt nenabízíme :()
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="pseudo-footer">
        <p>Jestli se vám tento příklad webu nelíbí, radši vyplňte níže nezávaznou poptávku a o všem si popovídáme.</p>
        <p>Nebojte, <b>Váš</b> produkt bude v dobrých rukou.</p>
        <p><i>© Copyright 2006. All right reserved.</i></p>
      </div>
    </div>
  </div>
</div>
