import { Component, OnInit, Input } from '@angular/core';
export interface Data {
  title: string;
  background: string;
}
@Component({
  selector: 'app-page-header-color',
  templateUrl: './page-header-color.component.html',
  styleUrls: ['./page-header-color.component.scss']
})
export class PageHeaderColorComponent implements OnInit {
  
  @Input() dataHeader: any;

  constructor() { }

  ngOnInit(): void {
  }

}
