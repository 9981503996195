import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(_router: Router) {

    _router.events.subscribe((y: NavigationEnd) => {
      if (y.url) {
        window.scrollTo(0, 0);
        document.querySelector('body').classList.remove('product');

        if (y instanceof NavigationEnd && environment.production) {
          gtag('config', environment.googleAnalyticsId, { 'page_path': y.url });
        }
      }
    });
  }
  ngOnInit(){
  }
}
