<footer>

  <div class="content container">
    <ul>
      <li class="logo">
        <svg _ngcontent-mau-c31="" width="126" height="27" viewBox="0 0 126 27" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path _ngcontent-mau-c31=""
            d="M2.196 4.644V7.956H0V12.708H2.196V18.936C2.196 21.636 2.772 23.364 3.852 24.444C4.788 25.38 6.372 26.064 8.28 26.064C9.936 26.064 11.376 25.848 12.132 25.56V20.628C11.592 20.772 11.34 20.772 10.584 20.772C9 20.772 8.532 19.908 8.532 17.784V12.708H12.204V7.956H8.532V2.916L2.196 4.644Z"
            fill="white"></path>
          <path _ngcontent-mau-c31=""
            d="M13.6339 7.956L18.7099 25.668H24.8299L26.1619 20.34C26.5219 18.828 26.8099 17.28 27.0619 15.012H27.1339C27.4939 17.28 27.7459 18.756 28.1059 20.412L29.3299 25.668H35.4499L40.7419 7.956H34.4059L33.3619 14.184C33.0019 16.2 32.6779 18.252 32.4619 20.34H32.3899C32.1019 18.252 31.6339 16.236 31.2379 14.256L29.8699 7.956H24.7939L23.3899 14.508C23.0299 16.2 22.5259 18.252 22.2019 20.34H22.1299C21.8779 18.252 21.5539 16.236 21.2659 14.472L20.2579 7.956H13.6339Z"
            fill="white"></path>
          <path _ngcontent-mau-c31=""
            d="M58.6322 19.08C58.7402 18.648 58.8842 17.676 58.8842 16.632C58.8842 11.772 56.4362 7.56 50.7122 7.56C44.1962 7.56 41.5322 12.888 41.5322 16.992C41.5322 22.608 45.0602 26.064 51.2882 26.064C53.4842 26.064 55.7882 25.776 57.9122 24.948L57.0842 20.592C55.5002 21.096 53.9162 21.276 52.2602 21.276C49.9562 21.276 47.9402 20.484 47.7242 19.08H58.6322ZM47.6522 14.688C47.7602 13.572 48.4802 11.808 50.3882 11.808C52.4402 11.808 52.9082 13.68 52.9082 14.688H47.6522Z"
            fill="white"></path>
          <path _ngcontent-mau-c31=""
            d="M61.8024 25.668H68.3184V15.624C68.3184 15.156 68.3544 14.652 68.4984 14.328C68.7864 13.644 69.3624 12.78 70.6224 12.78C72.2064 12.78 72.8904 13.968 72.8904 16.02V25.668H79.4064V15.228C79.4064 10.368 77.1384 7.56 72.9984 7.56C70.0464 7.56 68.3184 9.288 67.6704 10.224H67.5624L67.2744 7.956H61.6584C61.7304 9.612 61.8024 11.628 61.8024 14.004V25.668Z"
            fill="white"></path>
          <path _ngcontent-mau-c31=""
            d="M83.8991 4.644V7.956H81.7031V12.708H83.8991V18.936C83.8991 21.636 84.4751 23.364 85.5551 24.444C86.4911 25.38 88.0751 26.064 89.9831 26.064C91.6391 26.064 93.0791 25.848 93.8351 25.56V20.628C93.2951 20.772 93.0431 20.772 92.2871 20.772C90.7031 20.772 90.2351 19.908 90.2351 17.784V12.708H93.9071V7.956H90.2351V2.916L83.8991 4.644Z"
            fill="white"></path>
          <path _ngcontent-mau-c31=""
            d="M103.404 25.668V7.956H96.8884V25.668H103.404ZM100.164 0C98.1484 0 96.8524 1.296 96.8524 3.096C96.8524 4.788 98.1124 6.156 100.128 6.156C102.18 6.156 103.476 4.788 103.476 3.096C103.44 1.296 102.18 0 100.164 0Z"
            fill="white"></path>
          <path _ngcontent-mau-c31=""
            d="M116.292 7.56C110.244 7.56 106.536 11.304 106.536 16.956C106.536 22.716 110.64 26.064 115.932 26.064C120.9 26.064 125.508 23.076 125.508 16.632C125.508 11.304 121.836 7.56 116.292 7.56ZM116.112 12.132C118.02 12.132 118.812 14.364 118.812 16.812C118.812 19.656 117.876 21.492 116.112 21.492C114.096 21.492 113.232 19.476 113.232 16.812C113.232 14.472 113.952 12.132 116.112 12.132Z"
            fill="white"></path>
        </svg>
      </li>
      <li>
        Vyvíjíme webové aplikace a vytváříme individuální řešení.
      </li>
    </ul>
    <ul>
      <h3>Kontakt</h3>
      <li>
        <a href="mailto:jsme@twentio.cz">jsme@twentio.cz</a>
      </li>
    </ul>
  </div>
  <div class="overlay">
  
    <div class="circle-cut">
      <img src="../../../assets/images/circle-cut.svg">
    </div>
    <div class="cross">
      <img src="../../../assets/images/cross.svg">
    </div>
    <div class="rectangle">
      <img src="../../../assets/images/dots-rectangle.png">
    </div>
    <div class="top">
      <a class="button-effect background-white" (click)="scrollTop()">
        Zpět nahoru
      </a>
    </div>
  </div>
</footer>
