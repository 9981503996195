import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

export interface Data {
  title: string;
  image: string;
}
@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
  data: Data = { title: 'Redakční systém', image: '../../../assets/images/blocks.jpg' }

  public constructor(private titleService: Title) { }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  ngOnInit(): void {
    this.titleService.setTitle('Redakční systém | Twentio');
  }

}
