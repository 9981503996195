<div class="products">
  <div class="container">
    <div class="head">
      <h1>Naše</h1>
      <h1 class="colored">produkty</h1>
    </div>
    <ul>
      <li>
        <div class="content">
          <h3>Redakční systém</h3>
          <p>
            Na každý projekt z naší dílny využíváme základ našeho redakčního systému, který je <b>široce
              modulovatelný</b>, pokuď vámi hledaný modul
            schází
            (např. rezervační systém sedadel, <b>vyrobíme ho na míru</b> a přidáme do našeho katalogu). Vytváření
            statických
            stránek s vlastním designem, či námi vytvořenou šablonou, funguje <b>jako švýcarské hodinky</b>. S tímto
            produktem bude
            Váš web bude v perfektním souladu s požadavky na SEO optimalizaci a vysokými požadavky na rychlost načtení
            webové stránky.
          </p>
          <!---<div class="link">
            <a class="button-effect outline-effect" routerLink="/cms">
              Zobrazit více
            </a>
          </div>--->
          <div class="link">
            <a class="button-effect outline-effect" href="https://janov-sy.cz" target="_blank">
              Kdo už jede na našem CMS?
            </a>
          </div>
        </div>
      </li>
      <li>
        <div class="content">
          <h3>E-shopový systém</h3>
          <p>
            <b>Splníme Váš sen o úspěšném e-shopu</b>, pokuď vámi hledaný modul
            Naše řešení <b>Obvio</b> vám splní vaše podnikatelské sny a nápady. Tvorba vlastního eshopu je otázkou
            okamžiku a je tak jednoduchá, že ji zvládne opravdu každý.
          </p>
          <p>
            <i>vychází již brzy!</i>
          </p>
          <div class="link">
            <a class="button-effect outline-effect" href="https://obvio.cz" target="_blank">
              Zobrazit více
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
