<div class="servicies">
  <div class="container">
    <div class="head">
      <h1>Naše</h1>
      <h1 class="colored">služby</h1>
    </div>
    <ul class="our-servicies-list">
      <li>
        <div class="icon">
            <img src="../../../assets/logo/website.svg">
        </div>
        <div class="content">
          <h3>Kódování</h3>
          <p>
            Na míru <b>Vám</b> vyrobíme responzivní web, který přivede nové zákazníky a zvýší zisky.
          </p>
        </div>
      </li>
      <li>
        <div class="icon">
            <img src="../../../assets/logo/hands-shaking.svg">
        </div>
        <div class="content">
          <h3>Firemní identita</h3>
          <p>
            Pro <b>Vás</b> propojíme vaše onlinové aktivity tak, aby fungovaly a přinášely maximální zisk.
          </p>
        </div>
      </li>
      <li>
        <div class="icon">
            <img src="../../../assets/logo/design.svg">
        </div>
        <div class="content">
          <h3>Design</h3>
          <p>
            Pro váš produkt <b>Vám</b> navrhneme moderní a uživatelsky přívětivý design.
          </p>
        </div>
      </li>
    </ul>
  </div>
</div>
