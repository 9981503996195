import { Component, OnInit } from '@angular/core';

export interface TestimonialsImages {
  id: number;
  img: string;
}

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  actual: number = 0;

  dataImages: TestimonialsImages[] = [
    { id: 0, img: '../../../assets/images/testimonials/we-helped/intee.jpg' },
    { id: 1, img: '../../../assets/images/testimonials/we-helped/masinuv-statek.jpg' },
    { id: 2, img: '../../../assets/images/testimonials/we-helped/hotel.jpg' },
    { id: 3, img: '../../../assets/images/testimonials/we-helped/iris.jpg' },
    { id: 4, img: '../../../assets/images/testimonials/we-helped/janov.jpg' },
    { id: 5, img: '../../../assets/images/testimonials/we-helped/minies.jpg' },
  ]

  constructor() { }

  ngOnInit(): void {

  }

  autoRun() {
    setInterval(()=>{
      this.next();
    },7500)
  }
  next() {
    if (this.actual == this.dataImages.length - 1) {
      this.actual = 0;
    }
    else {
      this.actual += 1;
    }
  }
  back() {
    if (this.actual == this.dataImages.length - 1) {
      this.actual -= this.actual;
    }
    else {
      this.actual = this.dataImages.length - 1;
    }
  }
}
