import { Component, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
export interface Data {
  title: string;
  image: string;
}

export interface Contact {
  name: string;
  type: string;
  email: string;
  description: string;
  phoneNumber: string;
  externalWebsite: string;
  photo: string
}
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  data: Data = { title: 'Kontakt', image: '../../../assets/images/contact/kontakty.jpg' }
  easterEgg: boolean = false;
  easterWord: string = "";
  counter: number = 0;
  dataContacts: Contact[] = [
    {
      name: 'Filip Kalousek', type: 'CEO', email: 'kalousek@twentio.cz', description: 'Návrh stránek a frontend',
      phoneNumber: '+420 732 365 044', externalWebsite: 'https://filipkalousek.cz', photo: '../../../assets/images/contact/kalousek.jpg'
    },
    {
      name: 'Adam Janík', type: 'CTO', email: 'janik@twentio.cz', description: 'Full-stack developer',
      phoneNumber: '+420 724 153 351', externalWebsite: 'https://adamjanik.cz', photo: '../../../assets/images/contact/janik.jpg'
    },
    {
      name: 'Egon Procházka', type: 'Vývojař', email: 'prochazka@twentio.cz', description: 'Backend developer',
      phoneNumber: '', externalWebsite: 'https://www.linkedin.com/in/egon-procházka-980098168', photo: '../../../assets/images/contact/prochazka-portret.jpg'
    },
    {
      name: 'Martin Štumbauer', type: 'Designer', email: 'stumbauer@twentio.cz', description: 'Designer',
      phoneNumber: '', externalWebsite: 'https://www.linkedin.com/in/martin-%C5%A1tumbauer-a64745198/', photo: '../../../assets/images/contact/stumbauer-portret.jpg'
    },
  ]

  removeWhiteSpace(data) {
    return data.replace(/\s/g, '');
  }

  public constructor(private titleService: Title) { }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  ngOnInit(): void {
    this.titleService.setTitle('Kontakty | Twentio');
  }
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    this.easterWord = this.easterWord + event.key;
    if (this.easterWord == "nejlepsiprekladatelkaema") {
      this.easterEgg = true;
    }
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: KeyboardEvent) {
    this.counter = this.counter + 1;

    if (this.counter >= 7) {
      this.easterEgg = true;
    }
  }
}
