import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

interface DataHeader {
  title: TitleData;
  images: string[];
}
interface TitleData {
  text: string;
  coloredText: string;
}

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  data: DataHeader = {
    title: { text: 'nechte nás dát duši vašemu', coloredText: 'produktu' },
    images: [
      '../../../assets/images/testimonials/phone/irisdiagnostika.jpg',
      '../../../assets/images/testimonials/phone/intee.jpg',
      '../../../assets/images/testimonials/phone/masinuvstatek.jpg',
      '../../../assets/images/testimonials/phone/hotelpatria.jpg',
      '../../../assets/images/testimonials/phone/janov.jpg',
    ]
  }
  public constructor(private titleService: Title) { }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  ngOnInit(): void {
    this.titleService.setTitle('Twentio – zakázkový vývoj webových aplikací');
  }
}
