import { Component, OnInit, Input, NgModule } from '@angular/core';

interface DataHeader {
  title: TitleData;
  images: string[];
}
interface TitleData {
  text: string;
  coloredText: string;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  actual: number = 0;
  next: boolean = false;
  @Input() data: DataHeader;

  constructor() { }

  ngOnInit(): void {
    console.log(`
    %c _______________________________________
    < Nebuď kůň, pojď pracovat pro Twentio! >
     ---------------------------------------
            \\   ^__^
             \\  (oo)\\_______
                (__)\\       )\\/\\
                    ||-----V |
                    ||     ||`, "font-family:monospace")
    setInterval(() => {
      this.autoplay();
    }, 7000)
  }

  autoplay() {
    if (this.actual == this.data.images.length - 1) {
      this.actual = 0;
    }
    else {
      this.actual += 1;
    }
  }
}
