import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectData } from "../../views/project/project.component"

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit {

  dataProject:  ProjectData[] = [
    { title: 'Intee', beforeImage: '', text: 'Vytvoření SPA a API pro začínající Startup.', logo: '../../../assets/logo/reference/intee.svg', image: '', imageDetail: '../../../assets/images/reference/intee1.png', backgroundColor: '#85c318', type: [{ category: 'Programování' }, { category: 'Design' }], technology: 'Angular, C# - ASP.NET Core 3.x, Vercel, Azure', url: 'https://intee.cz', slug: 'intee' },
    { title: 'Mašínův statek', beforeImage: '', text: 'Vytvoření SPA a API pro Mašínův statek.', logo: '../../../assets/logo/reference/statek.svg', image: '', imageDetail: '../../../assets/images/reference/statek1.jpg', backgroundColor: '#312d2f', type: [{ category: 'Programování' }, { category: 'Design' }], technology: 'React, C# - ASP.NET Core 2.x', url: 'https://www.masinuvstatek.cz/', slug: 'masinuv-statek' },
    { title: 'Janov', beforeImage: '../../../assets/images/reference/old/janov.png', text: 'Remake SPA a API přidání Twentio Admin - Redakční systém, pro Obec Janov u Litomyšle.', logo: '../../../assets/logo/reference/janov.svg', image: '', imageDetail: '../../../assets/images/reference/janov1.jpg', backgroundColor: '#ec4747', type: [{ category: 'Programování' }, { category: 'Design' }, { category: 'Marketing' }], technology: 'Angular 9, C# - ASP.NET Core 5.0, ASPIFY.CZ', url: 'https://janov-sy.cz', slug: 'janov' },
    { title: 'Hotel Patria', beforeImage: '../../../assets/images/reference/old/hotel.png', text: 'Remake SPA a API přidání Twentio Admin - Redakční systém, pro Hotel Patria*** v Trutnově.', logo: '../../../assets/logo/reference/hotel.svg', image: '', imageDetail: '../../../assets/images/reference/hotel1.png', backgroundColor: '#ae854d', type: [{ category: 'Programování' }, { category: 'Design' }], technology: 'Angular 9, C# - ASP.NET Core 5.0, ASPIFY.CZ', url: 'https://hotelpatria.cz/', slug: 'hotel-patria' },
    { title: 'MadeInHradec', beforeImage: '', text: 'Chceme spojovat, poznávat a propagovat jednotlivé firmy a podnikatele v Hradci Králové. Zajímavé projekty pod značkou ‒ Made in Hradec. Podporujeme hradecké podnikatele využíváním našich systémů Twentio zdarma.', logo: '../../../assets/logo/reference/madeinhradec.svg', image: '', imageDetail: '../../../assets/images/madeinhradec.jpg', backgroundColor: '#212121', type: [{ category: 'Programování' }, { category: 'Design' }, { category: 'Marketing' }], technology: 'Vue 3, C# - ASP.NET Core 5.0, Vercel', url: 'https://madeinhradec.cz/', slug: 'madeinhradec' },
    { title: 'epergola.cz', beforeImage: '', text: 'Moderní hliníkové pergoly, uníkatní design, unikátní řešení na míru.', logo: '../../../assets/logo/reference/epergola.svg', image: '', imageDetail: '', backgroundColor: '#7057ff', type: [{ category: 'Programování' }, { category: 'Design' }, { category: 'Marketing' }], technology: 'Vue 3, C# - ASP.NET Core 5.0, Vercel', url: 'https://epergola.cz/', slug: 'epergola' },
    { title: 'Janovská traktoriada', beforeImage: '../../../assets/images/reference/old/janovskatraktoriada.png', text: 'Remake SPA a API přidání Twentio Admin - Redakční systém, pro událost Janovská traktoriáda. Soutěž plná pohody, bahna, domácích a sériově vyráběných traktůrků!', logo: '../../../assets/logo/reference/janovskatraktoriada.png', image: '', imageDetail: '../../../assets/images/reference/janovskatraktoriada.png', backgroundColor: 'linear-gradient(100.68deg,rgba(119,198,228,.95) 10.41%,rgba(163,215,236,.855) 49.43%,rgba(243,130,56,.95) 103.95%)', type: [{ category: 'Programování' }, { category: 'Design' }], technology: 'Angular 8, C# - ASP.NET Core 5.0, Forpsi.cz', url: 'https://janovskatraktoriada.cz/', slug: 'janovska-traktoriada' },
  ]

  constructor(private router: Router) { }

  goToComponent(name, data): void {
    this.router.navigate([name], { state: data });
  }
  ngOnInit(): void {
  }

}
