import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

interface DataHeader {
  title: TitleData;
  images: string[];
}
interface TitleData {
  text: string;
  coloredText: string;
}

@Component({
  selector: 'app-demand',
  templateUrl: './demand.component.html',
  styleUrls: ['./demand.component.scss']
})
export class DemandComponent implements OnInit {

  data: DataHeader = {
    title: { text: 'Nespoléhejte na design z', coloredText: 'roku 2006' },
    images: []
  }
  public constructor(private titleService: Title) { }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  ngOnInit(): void {
    this.titleService.setTitle('Nezávazná poptávka | Twentio');
  }
}
