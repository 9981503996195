import { Component, AfterViewInit, Input } from '@angular/core';

export interface Data {
  title: string;
  image: string;
}

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements AfterViewInit {

  @Input() dataHeader: any;

  constructor() { }

  ngAfterViewInit(): void {
  }

}
