import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

interface Servicies {
  id: number;
  icon: string;
  title: string;
  text: string;
  showed: boolean;
  route: Route;
  hasProject: boolean;
  project: Project[];
}
interface Route {
  title: string;
  url: string;
}
interface Project {
  title: string;
  image: string;
  route: string;
  type: string;
}

@Component({
  selector: 'app-servicies-list',
  templateUrl: './servicies-list.component.html',
  styleUrls: ['./servicies-list.component.scss']
})
export class ServiciesListComponent implements OnInit {

  /* 
  TODO

  PRIDAT DO URL NA REDIRECT PRI NEZAVAVZNE NABIDCE SLUG NEBO QUERY,
  PODLE KTEREHO ROZEZNA PAGE NA CO KLIKNUL A V MULTI SELECTU SE MU TO UKAZE
  
  +

  QUERY ZE BUDE HNED NA KONCI STRANKY
  
  */

  data: Servicies[] = [{
    id: 0,
    icon: '../../../assets/logo/website.svg',
    title: 'Tvorba webu',
    showed: false,
    hasProject: true,
    text: 'Cítíte se na zcela nový web, nebo předělání toho stávajícího? Pokud chcete uživatelsky přívětivý design s moderními prvky, který zaujme na pohled a bude přinášet zisky? Celý web si budete moci spravovat bez pomoci v administraci.',
    route: {
      title: 'Nezávazná poptávka',
      url: '/poptavka',
    },
    project: [{
      title: 'intee.cz',
      image: '../../../assets/images/servicies/gallery/website/intee.svg',
      route: 'https://intee.cz',
      type: 'website',
    },
    {
      title: 'hotelpatria.cz',
      image: '../../../assets/images/servicies/gallery/website/hotel-patria.svg',
      route: 'https://hotelpatria.cz',
      type: 'website',
    },
    ]
  },
  {
    id: 1,
    icon: '../../../assets/logo/hands-shaking.svg',
    title: 'Firemní identita',
    showed: false,
    hasProject: false,
    text: 'Potřebujete získat větší návšetěvnost? Nebo nemáte zkušenosti s e-commerce? Náš tým vám pomůže s chatboty, SEO, registrace vyhlédaváčů, tvorba článků a mnoho dalšího!',
    route: {
      title: 'Nezávazná poptávka',
      url: '/poptavka',
    },
    project: [{
      title: '',
      image: '',
      route: '',
      type: '',
    }]
  },
  {
    id: 2,
    icon: '../../../assets/logo/design.svg',
    title: 'Design',
    showed: false,
    hasProject: true,
    text: 'Uživatelské rozhrání, přehlednost stránek v tom jsme jednička (aspoň myslíme). <b>Vaším</b> požadavkům vyhovíme a přizpůsobíme se hlavním cílu vašeho podnikání. Děláme i plakáty! 😉',
    route: {
      title: 'Nezávazná poptávka',
      url: '/poptavka',
    },
    project: [{
      title: 'Plakát na akci Janovská traktoriáda',
      image: '../../../assets/images/servicies/gallery/design/plakat-oficialni_compressed.jpg',
      route: '../../../assets/images/servicies/gallery/design/plakat-oficialni.png',
      type: 'image',
    },
    {
      title: 'Design pro projekt EFS + základní kódování',
      image: '../../../assets/images/servicies/gallery/design/jdtsk_compressed.jpg',
      route: '../../../assets/images/servicies/gallery/design/jdtsk.png',
      type: 'image',
    }]
  },
  {
    id: 3,
    icon: '../../../assets/logo/consultation.svg',
    title: 'Konzultace',
    showed: false,
    hasProject: false,
    text: 'Nevíte u koho si nechat vytvořit <b>Váš</b> první web? Kontaktujte nás, nebudeme <b>Vám</b> vnucovat nás (ačkoli to je nejlepší volba), můžeme se setkat osobně nebo si zavolat a popovídáme si co by měl splňovat budoucí tvůrce <b>Váší</b> online identity.',
    route: {
      title: 'Kontakt',
      url: '/kontakt',
    },
    project: [{
      title: '',
      image: '',
      route: '',
      type: '',

    }]
  },
  {
    id: 4,
    icon: '../../../assets/logo/optimalization.svg',
    title: 'Optimalizace webu',
    hasProject: false,
    showed: false,
    text: 'Přicházíte o návštěvnost a zároveň o zákazníky z důvodu, že trvá dlouho než se načte <b>Váš</b> web? Nebo chcete být na vrcholu ve vyhledávání na Google vyhledávači, který hodnotí weby dle rychlosti? V tom případě je na čase nás kontaktovat a pomůžeme <b>Vám</b> optimalizovat stránky.',
    route: {
      title: 'Nezávazná poptávka',
      url: '/poptavka',
    },
    project: [{
      title: '',
      image: '',
      route: '',
      type: '',
    }]
  },
  ]

  toggle(item) {
    if (this.data[item].showed == true) {
      this.data[item].showed = false;
    } else {
      this.data[item].showed = true;
    }
  }

  constructor() { }

  ngOnInit(): void {
    //nastavi default fancybox
    this.setDefault();
  }
  setDefault() {
    jQuery(document).ready(function () { //Photos Gallery
      jQuery('[data-fancybox="gallery"]').fancybox({
        afterLoad: function (instance, current) {
          var pixelRatio = window.devicePixelRatio || 1;

          if (pixelRatio > 1.5) {
            current.width = current.width / pixelRatio;
            current.height = current.height / pixelRatio;
          }

        },
        lang: 'cs',
        i18n: {
          'cs': {
            CLOSE: "Zavřít",
            NEXT: "Další",
            PREV: "Zpět",
            ERROR: "Požadavek nelze načíst, zkuste to později, nebo kontaktujte správce.",
            PLAY_START: "Zahájit prezentaci",
            PLAY_STOP: "Zastavit prezentaci",
            FULL_SCREEN: "Zobrazit na celé obrazovce",
            THUMBS: "Miniatury",
            DOWNLOAD: "Stáhnout",
            SHARE: "Sdílet",
            ZOOM: "Přiblížit"
          }
        }
      });
    });
  }
}
