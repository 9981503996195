<div class="testimonials">
  <div class="container">
    <div class="head">
      <h1>Komu jsme už</h1>
      <h1 class="colored">pomohli?</h1>
    </div>
  </div>
  <div class="content">
    <ul>
      <li *ngFor="let element of dataImages" [class.actual]="element.id == actual">
        <img [src]="element.img">
      </li>
    </ul>
    <div class="operators">
      <a mat-icon-button color="black" (click)="back()" title="Zobrazit zpět">
        <span class="material-icons">
          arrow_back
        </span>
      </a>
      <a mat-icon-button color="black" (click)="next()" title="Zobrazit další">
        <span class="material-icons">
          arrow_forward
        </span>
      </a>
    </div>
    <div class="link">
      <a class="button-effect outline-effect" routerLink="/reference">
        Zobrazit vše
      </a>
    </div>
  </div>
</div>
