<div class="list-projects container">
  <ul>
    <li *ngFor="let project of dataProject" [title]="project.title">
      <a [routerLink]="['/projekt', project.slug]">
        <div class="logo">
          <img [src]="project.logo">
        </div>
      </a>
    </li>
  </ul>
</div>
