<div class="servicies">
  <div class="container">
    <ul>
      <li *ngFor="let item of data">
        <div class="icon">
          <img [src]="item.icon">
        </div>
        <div class="content">
          <h3>{{item.title}}</h3>
          <div class="show-operator">
            <button mat-button color="accent" (click)="toggle(item.id)">
              <span aria-label="Zobrazit více" *ngIf="!item.showed">
                Zobrazit více
              </span>
              <span aria-label="Schovat" *ngIf="item.showed">
                Skrýt
              </span>
            </button>
          </div>
          <div #data class="show" *ngIf="item.showed" [class.showed]="item.showed">
            <p [innerHTML]="item.text">
            </p>
            <div class="projects" *ngIf="item.hasProject == true">
              <h3>
                Naše ukázkové práce
              </h3>
              <ul>
                <li *ngFor="let project of item.project">
                  <div class="image" *ngIf="project.type == 'image'">
                    <a [href]="project.route" data-fancybox="gallery" [attr.data-caption]="project.title">
                      <img [src]="project.image">
                    </a>
                  </div>
                  <div class="website" *ngIf="project.type == 'website'">
                    <h4>{{project.title}}</h4>
                    <a [href]="project.route" target="_blank">
                      <img [src]="project.image">
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="button">
              <a class="button-effect outline-effect" [routerLink]="item.route.url">
                {{item.route.title}}
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="head">
      <h1>Co vám můžeme</h1>
      <h1 class="colored">nabídnout</h1>
    </div>
  </div>
</div>
