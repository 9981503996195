<header>
  <app-menu></app-menu>
  <div class="content container">
    <div class="image" *ngIf="data.images.length != 0">
      <div class="projects">
        <img [src]="data.images[actual]">
      </div>
    </div>
    <div class="text">
      <h1>
        {{data.title.text}}
      </h1>
      <h1 class="colored">
        {{data.title.coloredText}}
      </h1>
    </div>
  </div>
  <div class="overlay">
    <div class="cross">
      <svg width="93" height="93" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="70.0035" y="0.00012207" width="32" height="99" rx="4" transform="rotate(45 70.0035 0.00012207)"
          fill="white" />
        <rect x="0.00012207" y="22.6274" width="32" height="99" rx="4" transform="rotate(-45 0.00012207 22.6274)"
          fill="white" />
      </svg>
    </div>
    <div class="side">
      <svg width="175" height="208" viewBox="0 0 175 208" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M158.9 197.582C103.821 152.801 100.102 50.9702 31.2549 33.6756C20.84 31.0593 10.3969 30.0721 3.78669e-05 30.4543L5.48045e-05 208L158.9 208L174.5 208L158.9 197.582Z"
          fill="white" fill-opacity="0.63" />
      </svg>
    </div>
    <div class="under">
      <svg width="100%" height="175px" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path fill="#fff" d="
           M0 67
           C 273,183
             822,-40
             1920.00,106 
           
           V 359 
           H 0 
           V 67
           Z">
          <animate repeatCount="indefinite" fill="#fff" attributeName="d" dur="30s" attributeType="XML" values="
             M0 77 
             C 473,283
               822,-40
               1920,116 
             
             V 359 
             H 0 
             V 67 
             Z; 
 
             M0 77 
             C 473,-40
               1222,283
               1920,136 
             
             V 359 
             H 0 
             V 67 
             Z; 
 
             M0 77 
             C 973,260
               1722,-53
               1920,120 
             
             V 359 
             H 0 
             V 67 
             Z; 
 
             M0 77 
             C 473,283
               822,-40
               1920,116 
             
             V 359 
             H 0 
             V 67 
             Z
             ">
          </animate>
        </path>
      </svg>
    </div>
    <div class="circle-top">
      <svg width="241" height="236" viewBox="0 0 241 236" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 93C0 171.977 64.0233 236 143 236C180.927 236 215.405 221.235 241 197.14V0.432068H34C12.7946 25.3772 0 57.6944 0 93Z"
          fill="white" fill-opacity="0.47" />
      </svg>
    </div>
    <div class="circle-cut">
      <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M33.9749 11.4457C40.0715 10.2589 46.3889 11.0962 51.9658 13.8302C57.5428 16.5642 62.0739 21.0452 64.8698 26.5913C67.6657 32.1375 68.5733 38.4452 67.4544 44.5546C66.3355 50.664 63.2515 56.2407 58.6716 60.4361C54.0916 64.6314 48.2666 67.2158 42.0827 67.7959C35.8988 68.3761 29.6947 66.9202 24.4144 63.6499C19.1341 60.3796 15.0666 55.4738 12.831 49.6791C10.5953 43.8844 10.3138 37.518 12.0294 31.5486"
          stroke="white" stroke-opacity="0.63" stroke-width="10" />
      </svg>
    </div>
    <div class="outline-triangular-prism">
      <svg width="159" height="209" viewBox="0 0 159 209" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 130.5L60 50L158.5 107L108.5 188L31 209L10 130.5Z" fill="white" fill-opacity="0.63" />
        <path d="M54.23 25.0572L144.818 77.3585L73.3736 96.5021L54.23 25.0572Z" stroke="black" stroke-width="4" />
        <path d="M4.18154 105.951L94.77 158.253L23.3251 177.396L4.18154 105.951Z" stroke="black" stroke-width="4" />
        <line x1="22.8629" y1="177.09" x2="74.0321" y2="93.6037" stroke="black" stroke-width="4" />
        <line x1="2.22272" y1="102.569" x2="52.4943" y2="21.7757" stroke="black" stroke-width="4" />
        <line x1="97.3796" y1="158.227" x2="147.651" y2="77.4334" stroke="black" stroke-width="4" />
      </svg>
    </div>
    <div class="circle-dots">
      <img src="../../../assets/images/dots.png">
    </div>
    <div class="circle-outline">
      <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="44" cy="44" r="36" stroke="white" stroke-width="16" />
      </svg>
    </div>
    <div class="rectangle">
      <svg width="23" height="295" viewBox="0 0 23 295" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="295" width="295" height="23" transform="rotate(-90 0 295)" fill="white" fill-opacity="0.18" />
      </svg>
    </div>
  </div>
</header>
