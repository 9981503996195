import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomepageComponent } from './views/homepage/homepage.component';
import { CustomComponent } from './views/custom/custom.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ServiciesComponent } from './components/servicies/servicies.component';
import { ProductsComponent } from './components/products/products.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { ReferenceComponent } from './views/reference/reference.component';
import { ContactComponent } from './views/contact/contact.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { DemandComponent } from './views/demand/demand.component';
import { DemandDesignComponent } from './components/demand-design/demand-design.component';
import { OurServiciesComponent } from './views/our-servicies/our-servicies.component';
import { ErrorComponent } from './components/error/error.component';
import { ServiciesListComponent } from './components/servicies-list/servicies-list.component';
import { ProjectComponent } from './views/project/project.component';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { CmsComponent } from './views/cms/cms.component';
import { PageHeaderColorComponent } from './components/page-header-color/page-header-color.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    HeaderComponent,
    HomepageComponent,
    CustomComponent,
    ServiciesComponent,
    ProductsComponent,
    TestimonialsComponent,
    ReferenceComponent,
    ContactComponent,
    PageHeaderComponent,
    DemandComponent,
    DemandDesignComponent,
    OurServiciesComponent,
    ErrorComponent,
    ServiciesListComponent,
    ProjectComponent,
    ProjectDetailComponent,
    ProjectsListComponent,
    CmsComponent,
    PageHeaderColorComponent,
    ContactFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    [LottieModule.forRoot({ player: playerFactory })],
  ],
  providers: [
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
